.shared-tooltip {
  background-color: rgb(34, 34, 34);
  border: none;
  border-radius: 10px;
  padding: 14px;

  & .content {
    color: #fff;
  }



}

.bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^="top"]>.arrow::after {
  border-top-color: var(--color-arrow-tooltip-var) !important;
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.arrow::after {
  border-bottom-color: var(--color-arrow-tooltip-var) !important;
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^="left"]>.arrow::after {
  border-left-color: var(--color-arrow-tooltip-var) !important;
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^="right"]>.arrow::after {
  border-right-color: var(--color-arrow-tooltip-var) !important;
}