@import "variables";
.shared-input {
  width: $widthInput;
  height: $heightInput;
  border-radius: $borderRadiusInput;
  font-size: $fontSizeInput;
  font-family: $fontFamilyInput;
  border: none;
  background: $bgInput;
  //box-shadow: $boxShadow;
  &.error {
    border: 1px solid $errorInput;
  }
  &.form-control:disabled {
    opacity: 0.6;
  }
  &.form-control:focus {
    box-shadow: $boxShadow;
  }
}
.input-group.sharedInputGroup {
  .input-group-append ~ input {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    order: 1;
  }
  .input-group-append {
    padding-right: 8px;
    border: 1px solid #ced4da;
    border-left: none;
    order: 2;
  }
  input[type="color"] {
    //background-color: red;
  }
  .input-group-text {
    font-family: 'Helvetica Neue', 'helvetica';
  }
}
.md {
  height: $heightMediumInput;
  font-size: $fontSizeMediumInput;
  line-height: $lineheightMediumInput;
}

.sm {
  height: $heightSmallInput;
  font-size: $fontSizeSmallInput;
  line-height: $lineheightSmallInput;
}
