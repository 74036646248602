$md-device: 992px !default;

.login-container {
    background: #000;
    width: 100%;
    min-height: vh(100);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    overflow: hidden;

    @media (max-width: $md-device) {
        height: vh(100);
        padding-top: 150px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    & .login-panel {
        transform: translateY(100%);

        @include create-breakpointMinWidth('xs') {}

        @include create-breakpointMaxWidth('sm') {
            padding: 15px;
        }

        @include create-breakpointMaxWidth('md') {
            padding: 25px;
        }

        @include create-breakpointMinWidth('sm') {
            width: 360px;
        }

        @include create-breakpointMinWidth('md') {
            width: 430px;
        }

        @include create-breakpointMinWidth('lg') {
            width: 530px;
        }

        @include create-breakpointMinWidth('xl') {
            width: 600px;
        }

        max-height: vh(95);
        position: fixed;
        bottom: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 50px;
        padding-bottom: 0px;
        //animation: 0.3s ease-in-out forwards anim_slideUp;
        transition: 0.4s ease-in-out;

        &.in {
            transform: translateY(0%);

        }

        & .content {
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            overflow-x: hidden;
            padding: 0px 15px;
        }

        & .logo {
            max-height: 120px;
            max-width: 100%;
            display: block;
            margin: 0px auto;
        }

        & .login-title {
            align-self: flex-start;
            margin-top: 24px;
            margin-bottom: 24px;
        }

        & .content-step {
            display: flex;
            width: 100%;
            transform: translateX(0%);
            transition: transform 0.2s ease-in-out;

            & .step {
                width: 100%;
                flex-shrink: 0;
            }
        }


    }
}

@keyframes anim_slideUp {
    0% {
        transform: translateY(vh(80));
    }

    100% {
        transform: translateY(0);
    }
}
