@import "variables";
@import "../../../shared/mediaQuery";

.heading-5 {
  font-family: $fontFamilyHeading5;
  font-size: $fontSizeHeading5;
  color: $colorHeading5;
  letter-spacing: $letterSpacingHeading5;
  line-height: $lineHeightHeading5;
  @media (max-width: $lg-device) {
    font-size: $fontSizeHeading5Mobile;
  }
}
