@import "../../../../components/shared/mediaQuery";

.menu-tool {
    position: absolute;
    right: 15px;
    bottom: 15px;

    .center-tool {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;

        & img {
            width: 54px;
        }

        &.noIcon {
            cursor: auto;

            &:hover {
                transform: scale(1);
            }
        }

        &:hover {
            transform: scale(1.1);
        }

        &.dark {
            background-color: $dark;
        }

        & .notify {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: $accent-primary;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media (max-width: $lg-device) {
        transform: scale(0.8);
    }

    @media (max-width: $md-device) {
        bottom: 50px;
        transform: scale(0.6);
    }



}

.tool-item {
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.noIcon {
        cursor: auto;

        &:hover {
            transform: scale(1);
        }
    }

    & img {
        width: 24px;
    }

    &:nth-child(1) {
        top: -60px;
        left: 10px;
    }

    &:nth-child(2) {
        top: -30px;
        left: -40px;
    }

    &:nth-child(3) {
        top: 30px;
        left: -60px;
    }

    &:hover {
        transform: scale(1.1);
    }

    &.dark {
        background-color: $dark;
    }
}