$primary: #4d4d4d;
$accent-primary: #81358A;
$success: #7ed321;
$error: #a10000;
$warning: #ffcd00;
$secondary: #ffffff;
$dark: #000;
$light: #fff;
$color-accent: #5F259E;


$breakpoints: (xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px) !default;

@mixin create-breakpointMinWidth($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
}

@mixin create-breakpointMaxWidth($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
}
.primary{
    color: $accent-primary;
}