.article-list {
    h1 {
        font-size: 40px;
    }

    .text {
        font-size: 19px;
        font-weight: 200;
        line-height: normal;
    }
}