@import "variables";

.carousel-shared {
  display: flex;
  align-items: center;
  height: 100%;

  .slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 80%;
    height: 100%;

    .slideshowSlider {
      display: flex;
      align-items: flex-start;
      height: 100%; 
      //transition: ease 1000ms;

      .slide {
        //display: inline-block;
        width: 100%;
       // height: 100%;

        /*  display: inline-block;
      height: 400px;
      */
        img {
          width: auto;
          max-width: 100%;
          object-fit: cover;
        }

        video {
          width: auto;
          max-width: 100%;
          object-fit: cover;
        }

        iframe {
          width: auto;
          max-width: 100%;
          object-fit: cover;
        }
      }
    }

  }

  .prev-slide,
  .next-slide {
    width: 10%;
    display: flex;
    justify-content: center;

    &.disabled {
      opacity: 0.2;
    }
  }


}