.logo {
    max-height: 120px;
    max-width: 100%;
    display: block;
    margin: 0px auto;
}

.content-box {
    margin-top: 60px;
    padding: 30px 15px;
    transform: translateX(-100%);
    transition: transform 0.4s ease-out;

    &.in {
        transform: translateX(0%);
    }
}

.registration-box {
    padding: 30px 15px;
    transform: translateY(-130%);
    transition: transform 0.4s ease-out;

    &.in {
        transform: translateY(0%);
    }

    @include create-breakpointMaxWidth('lg') {
        transform: translateX(-100%);

        &.in {
            transform: translateX(0%);
        }
    }
}

.login-box {
    padding: 30px 15px;
    transform: translateY(130%);
    transition: transform 0.4s ease-out;
    &.login-guest{
        transform: translateY(260%);
        @include create-breakpointMaxWidth('lg') {
            transform: translateX(-100%);
    
            &.in {
                transform: translateX(0%);
            }
        }
    }
    &.in {
        transform: translateY(0%);
    }

}

@include create-breakpointMaxWidth('lg') {
    .login-box {

        transform: translateY(0%);
        margin-bottom: 80px;
        transform: translateX(-100%);

        &.in {
            transform: translateX(0%);
        }
    }
}