 .menu-areas-content {
     height: calc(#{vhVar(100)} - 162px);
     background-color: rgb(227, 227, 227);
     position: relative;
     top: 0px;
     display: flex;
     flex-direction: column;
     padding: 38px;
     overflow: auto;
     -webkit-overflow-scrolling: touch;
     padding-top: 25px;
     padding-bottom: 105px;


     & .menu-areas-item {
         font-size: 20px;
         border-bottom: 1px solid rgb(177, 177, 177);
         padding: 10px;
         width: 100%;

         span {
             color: #545454;
         }

         &.active {
             background-color: rgba($accent-primary, 0.1);
         }
     }
 }