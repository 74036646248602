@import "variables";
.shared-modal {
  & .modal-content {
    border: none;
    & .modal-header {
      border: none;
      & .modal-title {
        width: 100%;
        font-size: 18px;
      }
    }
    & .modal-body {
      font-size: 16px;
      font-family: $font-family-regular;
      padding-left: 24px;
      padding-right: 24px;
      overflow-wrap: break-word;
    }
    & .modal-footer {
      border: none;
    }
  }
}
