@import "variables";

.shared-button {
  height: $heightButton;
  border-radius: $borderRadiusButton;
  font-size: $fontSizeButton;
  font-family: $fontFamilyButton;
  line-height: normal;
  font-weight: $fontWeightButton;
  padding: 0px 16px;

  &.btn {
    &:focus {
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }
  }

  &.btn-primary .md {
    height: $heightMediumButton;
    font-size: $fontSizeMediumButton;
    line-height: $lineheightMediumButton;
  }

  &.btn-md {
    height: $heightMediumButton;
    font-size: $fontSizeMediumButton;
    line-height: $lineheightMediumButton;
    padding: 0px 14px;
  }

  &.btn-sm {
    height: $heightSmallButton;
    font-size: $fontSizeSmallButton;
    line-height: $lineheightSmallButton;
  }

  &.accent {
    background-color: $color-accent;
    border-radius: 8px;
  }

  &.complement {
    background-color: $color-complement;
    border: none;
    color: #000;
    border-radius: 8px;
  }
}
