@media (max-width: $md-device) {
    .center-div-custom {
        top: 88px;
    }

    .sendBird-app {
        border-radius: 0px;

        .sendbird-app__wrap {
            display: flex;
            flex-direction: column;
            background: transparent;

            .header-chat {
                background-color: $accent-primary;
                color: #fff;
                height: $height-header-chat*.60;

                h4 {
                    flex-basis: auto;
                    margin-bottom: 0px;
                    padding: 20px;
                }

                .img {
                    margin: auto;
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    cursor: pointer;

                    img {
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                    }
                }
            }

            .message-chat {
                height: calc(100% - 47px);
            }

            .sendbird-app__channellist-wrap {
                width: 100%;
                max-width: none;
            }
        }
    }
}