.profile {
    display: flex;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    margin: 0px -24px;
    max-height: vh(50);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    position: relative;

    .left-panel {
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding: 20px;
        text-align: center;
        min-width: 250px;

        h5 {
            font-size: 16px;
        }

        span {
            font-size: 14px;
        }


        .img {
            margin: auto;
            width: 120px;
            height: 120px;
            //background-color: yellow;
            border-radius: 12px;
        }
    }

    .right-panel {
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding: 20px;
        border-left: 1px solid #ededed;

        .info-detail {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 18px;

            div {
                display: flex;
                align-items: baseline;

            }

            h6 {
                margin-right: 12px;
            }

            span {
                font-size: 16px;
            }
        }

        h3 {
            text-transform: uppercase;
        }

        & .contact-btn {
            display: flex;
            align-items: center;

            .icon {
                color: $accent-primary;
                margin-right: 4px;
            }
        }

        b {
            font-size: 18px;
        }

        span {
            display: flex;
            align-items: center;
            line-height: normal;
            font-size: 12px;
            font-weight: 300;

            .icon {
                font-size: 16px;
                color: $accent-primary;
            }
        }

        .bio {
            font-size: 14px;
            line-height: normal;
        }

    }

    .expand-icon {
        position: absolute;
        right: 20px;
        bottom: 0px;

        & .rotateIcon {
            transform: scaleY(-100%);
        }
    }

    &.open {
        .expand-icon {
            position: fixed;
            bottom: 50px;
        }

    }

    @media (max-width: $md-device) {
        flex-direction: column;
        height: calc(#{vhVar(100)} - 160px);
        margin: 0px 0px;
        border: none;


        .left-panel {
            display: flex;
            flex-direction: row;
            padding: 8px;
            border: none;

            .img {
                width: 45px;
                height: 45px;
                margin: 12px;

                img {
                    width: 45px;
                    height: 45px;
                }
            }
        }

        .right-panel {
            display: flex;
            flex-direction: column;
            justify-content: start;
            padding: 20px;
            padding-top: 0px;
            border: none;

            & .title-user {
                display: none !important;
            }

        }

        &.open {
            .expand-icon {
                position: fixed;
                bottom: 20px;
                right: 40px;
            }

        }



    }


}