.lang-select {
  .css-yk16xz-control {
    background: #fff;
  }

  .css-26l3qy-menu {
    border-radius: 12px;
    border: none;
    margin-top: 20px;
    overflow: hidden;

    & .css-4ljt47-MenuList {
      background-color: transparent;
      border: none;

      &>div,
      & .css-1n7v3ny-option {
        background-color: transparent;
        border: none;

      }

    }
  }
}