$fontSizeCaption: 16px !default;
$fontSizeCaptionMobile: 12px !default;

$fontFamilyCaptionRegular: 'Helvetica Neue', 'helvetica' !default;
$fontFamilyCaptionMedium: 'Helvetica Neue', 'helvetica' !default;
$fontFamilyCaptionEmphasis: 'Helvetica Neue', 'helvetica' !default;
$primary: #0393f3 !default;
$error: #a10000 !default;
$colorCaption: #4d4d4d !default;
$letterSpacingCaption: 0 !default;
$lineHeightCaption: 14px !default;
