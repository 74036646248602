@import "variables";
.shared-error {
  color: $colorError;
  font-size: $fontSizeError;
  font-family: $fontFamilyError;
  margin-top: $marginTopError;
  margin-bottom: $marginBottomError;
  text-align: center;
  &.success {
    color: $colorSuccessError;
  }
}
