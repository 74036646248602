$errorInput: #a10000 !default;

$bgInput:#e6e6e6 !default;
$widthInput: 100% !default;

$heightInput: 38px !default;
$heightMediumInput: 28px !default;
$heightSmallInput: 24px !default;

$fontFamilyInput: 'Helvetica Neue', 'helvetica' !default;

$fontSizeInput: 14px !default;
$fontSizeMediumInput: 16px !default;
$fontSizeSmallInput: 16px !default;

$lineheightMediumInput: 24px !default;
$lineheightSmallInput: 19px !default;

$borderRadiusInput: $heightInput/2 !default;
$boxShadow:  inset 0px 0px 3px 3px rgba(0, 0, 0, 0.1);