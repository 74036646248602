@import "navbar";
@import "menu";
@import "sendBird";
@import "message";
@import "calendar";
@import "cardActionTypes";
@import "area";

body {}

.authHome-container {
    width: 100%;

}

.div-spot {
    text-align: center;
    cursor: pointer;

    img {
        max-height: 100%;
        max-width: 100%;
    }
}

.area-container {
    width: 100%;
    height: vh(100);
    background-position: bottom;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    overflow: hidden;
    display: flex;
    justify-content: center;

    >img {
        @media (min-aspect-ratio: 16/9) {
            width: 100%;
            left: 0;
        }

        @media (max-aspect-ratio: 16/9) {
            height: 100%;
        }

        position: absolute;
        bottom: 0;
    }
}


@media (max-width: $md-device) {
    body {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .authHome-container {

    }
}