@import "variables";
.shared-dropdown {
  position: static;
  &.dropleft {
    & .dropdown-toggle {
      width: 36px;
      height: 36px;
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      &::before {
        display: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
  &.show {
    &.dropleft {
      & .dropdown-toggle {
        background: #f5f6f9;
        border-radius: 50%;
      }
    }
    & .dropdown-menu {
      &.show {
        width: 240px;
        padding: 20px;
        border: none;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
        & .dropdown-item {
          padding-left: 4px;
          &.disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }
  &.wrap2Col {
    & .dropdown-menu {
      &.show {
        display: flex;
        flex-wrap: wrap;
        padding: 0px;
        & .dropdown-item {
          width: 50%;
          padding: 6px;
        }
      }
    }
  }
}
