@import "variables";
.shared-toolbar {
  margin-bottom: 6px;
}
.shared-dropToolbar {
  &:before {
    content: "\e908";
    font-family: "icon-pack";
    position: absolute;
    right: 24px;
    top: -12px;
    color: #0091f6;
    font-size: 12px;
  }
  position: absolute;
  bottom: -42px;
  right: 14px;
  display: flex;
  z-index: 10;
  color: #fff;
  font-weight: bolder;
  border-radius: 8px;
  background-color: #0090f6;
  & .itemDrop {
    background-color: #0091f6;
    display: flex;
    min-height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: 0px 12px;
    cursor: pointer;
    &:hover {
      background-color: #1b3aa6;
    }
  }
}
