@import "variables";

.shared-msgList {
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  left: 30px;
  min-width: 280px;
  max-height: vh(90);

  overflow: auto;

  @media (max-width: $md-device) {
    left: 5px;
    bottom: 80px;

  }
}