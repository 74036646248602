@import "variables";
.shared-stateIcon {
  display: flex;
  align-items: center;
  & div {
    width: $widthStateIcon;
    height: $widthStateIcon;
    border-radius: 50%;
    position: absolute;
    margin-left: 12px;
    &.red {
      background-color: $colorStateIconRed;
    }
    &.green {
      background-color: $colorStateIconGreen;
    }
    &.orange {
      background-color: $colorStateIconOrange;
    }
    &.yellow {
      background-color: $colorStateIconYellow;
    }
  }
  & span {
    margin-left: $widthStateIcon + 20px;
  }
}
