.item-list {
    border-bottom: 1px solid rgba($accent-primary, 0.11);
    padding: 10px 0px;
    position: relative;

    .btn-tool {
        display: flex;

        img {
            &:hover {
                cursor: pointer;
            }

            margin-right: 12px;
        }
    }


    .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        color: #000;
        font-size: 20px;
    }

    .text {
        font-weight: 200;
        font-size: 19px;
        line-height: normal;
        max-height: 48px;
        overflow: hidden;
        transition: all 1s ease-in-out;

        &.open {
            max-height: 2500px;
        }
    }

    .expand {
        position: absolute;
        right: 0px;
        bottom: 0px;

        &.rotateIcon {
            transform: scaleY(-100%);
        }

    }
}

.contentIframe {
    height: calc(100% - 14px);
}

@media (max-width: $md-device) {
    .item-list {
        .title {
            max-width: 63vw;
        }
    }
}