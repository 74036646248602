@import "variables";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?54e8d2");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?54e8d2#iefix") format("embedded-opentype"),
  url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?54e8d2") format("truetype"),
  url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?54e8d2") format("woff"),
  url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?54e8d2##{$icomoon-font-family}") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-next-left {
  &:before {
    content: $icon-next-left;
    color: #4d4d4d;
  }
}

.icon-search {
  &:before {
    content: $icon-search;
    color: #5f259e;
  }
}

.icon-share {
  &:before {
    content: $icon-share;
    color: #5f259e;
  }
}

.icon-msg {
  &:before {
    content: $icon-msg;
    color: #5f259e;
  }
}

.icon-close-x {
  &:before {
    content: $icon-close-x;
  }
}

.icon-article {
  &:before {
    content: $icon-article;
    color: #5f259e;
  }
}

.icon-bag {
  &:before {
    content: $icon-bag;
    color: #5f259e;
  }
}

.icon-expand-down {
  &:before {
    content: $icon-expand-down;
    color: #4d4d4d;
  }
}

.icon-folders {
  &:before {
    content: $icon-folders;
    color: #5f259e;
  }
}

.icon-link {
  &:before {
    content: $icon-link;
    color: #5f259e;
  }
}

.icon-letters {
  &:before {
    content: $icon-letters;
    color: #5f259e;
  }
}

.icon-next-right {
  &:before {
    content: $icon-next-right;
    color: #4d4d4d;
  }
}

.icon-show-down {
  &:before {
    content: $icon-show-down;
    color: #5f259e;
  }
}

.icon-show-up {
  &:before {
    content: $icon-show-up;
    color: #5f259e;
  }
}

.icon-videocall {
  &:before {
    content: $icon-videocall;
    color: #5f259e;
  }
}

.icon-maximize {
  &:before {
    content: $icon-maximize;
  }
}

.icon-copy {
  &:before {
    content: $icon-copy;
    color: #5f259e;
  }
}

.icon-video {
  &:before {
    content: $icon-video;
  }
}

.icon-minus-under {
  &:before {
    content: $icon-minus-under;
    color: #5f259e;
  }
}

.icon-group_add {
  &:before {
    content: $icon-group_add;
    color: #5f259e;
  }
}

.icon-group {
  &:before {
    content: $icon-group;
    color: #5f259e;
  }
}

.icon-add_user_chat {
  &:before {
    content: $icon-add_user_chat;
    color: #5f259e;
  }
}

.icon-close_call {
  &:before {
    content: $icon-close_call;
    color: #5f259e;
  }
}

.icon-close-bold {
  &:before {
    content: $icon-close-bold;
    color: #4d4d4d;
  }
}

.icon-down {
  &:before {
    content: $icon-down;
    color: #4d4d4d;
  }
}

.icon-info {
  &:before {
    content: $icon-info;
    color: #5f259e;
  }
}

.icon-join_call {
  &:before {
    content: $icon-join_call;
    color: #5f259e;
  }
}

.icon-mail {
  &:before {
    content: $icon-mail;
    color: #5f259e;
  }
}

.icon-pin {
  &:before {
    content: $icon-pin;
    color: #5f259e;
  }
}

.icon-send {
  &:before {
    content: $icon-send;
    color: #5f259e;
  }
}

.icon-web {
  &:before {
    content: $icon-web;
    color: #5f259e;
  }
}

.icon-check_box {
  &:before {
    content: $icon-check_box;
  }
}

.icon-check_box_blank {
  &:before {
    content: $icon-check_box_blank;
  }
}

.icon-question_answer {
  &:before {
    content: $icon-question_answer;
  }
}

.icon-quiz {
  &:before {
    content: $icon-quiz;
  }
}

.icon-gavel {
  &:before {
    content: $icon-gavel;
  }
}

.icon-leaderboard {
  &:before {
    content: $icon-leaderboard;
  }
}

.icon-insights {
  &:before {
    content: $icon-insights;
  }
}

.icon-stars {
  &:before {
    content: $icon-stars;
  }
}

.icon-video_settings {
  &:before {
    content: $icon-video_settings;
  }
}

.icon-play_circle_outline {
  &:before {
    content: $icon-play_circle_outline;
  }
}

.icon-add_task {
  &:before {
    content: $icon-add_task;
  }
}

.icon-published_with_changes {
  &:before {
    content: $icon-published_with_changes;
  }
}

.icon-save {
  &:before {
    content: $icon-save;
  }
}

.icon-exit_to_app {
  &:before {
    content: $icon-exit_to_app;
  }
}

.icon-logout {
  &:before {
    content: $icon-logout;
  }
}

.icon-arrow_circle_up {
  &:before {
    content: $icon-arrow_circle_up;
  }
}

.icon-chevron_left {
  &:before {
    content: $icon-chevron_left;
  }
}

.icon-chevron_right {
  &:before {
    content: $icon-chevron_right;
  }
}

.icon-expand_more {
  &:before {
    content: $icon-expand_more;
  }
}

.icon-expand_less {
  &:before {
    content: $icon-expand_less;
  }
}

.icon-save_alt {
  &:before {
    content: $icon-save_alt;
  }
}

.icon-arrow_back {
  &:before {
    content: $icon-arrow_back;
  }
}

.icon-arrow_forward {
  &:before {
    content: $icon-arrow_forward;
  }
}

.icon-pin_drop {
  &:before {
    content: $icon-pin_drop;
  }
}

.icon-arrow_drop_up {
  &:before {
    content: $icon-arrow_drop_up;
  }
}

.icon-arrow_drop_down {
  &:before {
    content: $icon-arrow_drop_down;
  }
}

.icon-radio_button_checked {
  &:before {
    content: $icon-radio_button_checked;
  }
}

.icon-radio_button_unchecked {
  &:before {
    content: $icon-radio_button_unchecked;
  }
}

.icon-check_circle_black {
  &:before {
    content: $icon-check_circle_black;
  }
}

.icon-done_black {
  &:before {
    content: $icon-done_black;
  }
}

.icon-upload_black {
  &:before {
    content: $icon-upload_black;
  }
}

.icon-upload_file_black {
  &:before {
    content: $icon-upload_file_black;
  }
}

.icon-download {
  &:before {
    content: $icon-download;
  }
}

.icon-category {
  &:before {
    content: $icon-category;
  }
}

.icon-add_circle {
  &:before {
    content: $icon-add_circle;
  }
}

.icon-add {
  &:before {
    content: $icon-add;
  }
}

.icon-remove {
  &:before {
    content: $icon-remove;
  }
}

.icon-remove_circle {
  &:before {
    content: $icon-remove_circle;
  }
}

.icon-delete {
  &:before {
    content: $icon-delete;
  }
}

.icon-redo {
  &:before {
    content: $icon-redo;
  }
}

.icon-undo {
  &:before {
    content: $icon-undo;
  }
}

.icon-saved_search {
  &:before {
    content: $icon-saved_search;
  }
}

.icon-share_location {
  &:before {
    content: $icon-share_location;
  }
}

.icon-add_location {
  &:before {
    content: $icon-add_location;
  }
}

.icon-not_listed_location {
  &:before {
    content: $icon-not_listed_location;
  }
}

.icon-where {
  &:before {
    content: $icon-where;
  }
}

.icon-location_searching {
  &:before {
    content: $icon-location_searching;
  }
}

.icon-sports {
  &:before {
    content: $icon-sports;
  }
}

.icon-golf {
  &:before {
    content: $icon-golf;
  }
}

.icon-star {
  &:before {
    content: $icon-star;
  }
}

.icon-star_black {
  &:before {
    content: $icon-star_black;
  }
}

.icon-history {
  &:before {
    content: $icon-history;
  }
}

.icon-travel {
  &:before {
    content: $icon-travel;
  }
}

.icon-block {
  &:before {
    content: $icon-block;
  }
}

.icon-cancel {
  &:before {
    content: $icon-cancel;
  }
}

.icon-close {
  &:before {
    content: $icon-close;
  }
}

.icon-app {
  &:before {
    content: $icon-app;
  }
}

.icon-menu {
  &:before {
    content: $icon-menu;
  }
}

.icon-widgets {
  &:before {
    content: $icon-widgets;
  }
}