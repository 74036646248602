.channel-name {
    background-color: rgba($accent-primary, 0.2);
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 12px;
    padding-left: 30px;
    cursor: pointer;
    &.active{
        background-color: rgba(#ccc, 0.3);
    }

    .cover {
        width: 56px;
        border-radius: 50%;
    }

    .title {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
    }

    h5 {
        font-size: 16px;
        margin-bottom: 0px;
        line-height: 0.6;
    }

    small {
        font-size: 10px;
    }
}