.grid-container {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    padding: 0px 26px;

    .item-grid {
        display: flex;
        flex-direction: column;
        min-width: 170px;
        margin: 16px 12px;

        .element {
            width: 160px;
            height: 160px;
            display: flex;
            overflow: hidden;
            border-radius: 25px;
            -webkit-box-shadow: 9px 11px 25px -6px rgba(0, 0, 0, 0.44);
            box-shadow: 9px 11px 25px -6px rgba(0, 0, 0, 0.44);
            border: 1px solid $accent-primary;

            iframe {
                width: 160px;
            }

            video {
                width: 160px;
            }
        }

        .caption {
            margin-top: 18px;
            font-size: small;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 170px;



            span {

                &:hover {
                    cursor: pointer;
                }
            }

            .btn-tool {
                margin-top: 8px;
                display: flex;
                justify-content: center;
                width: 100%;

                img {
                    margin-right: 12px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    @media (max-width: $md-device) {
        padding: 0px;
        justify-content: flex-start;
        .item-grid {
            min-width: 145px;

            .element {
                width: 145px;
                height: 145px;
            }
        }
    }
    //Custom media query for mediagrid item
    @media (max-width: 475px) {
        padding: 0px 15px;
        justify-content: space-between;
        .item-grid {
            min-width: 100px;
            margin: 16px 0px;

            .element {
                width: 100px;
                height: 100px;
            }
            .caption {
                max-width: 100px;
            }
        }
    }
}

.content-grid-fullscreen {
    height: 100%;

    img {
        max-width: 100%;
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    video {
        width: 100%;
        height: 100%;
    }

    .h-100-controlled {
        height: calc(100% - 20px);
    }
}