@import "variables";
@import "./contentBoxEmpty/";

.shared-boxContent {
  width: $widthBoxContent;
  border-radius: $borderRadiusBoxContent;
  border: none;
  background-color: $defaultBgBoxContent;

  &.dark {
    background-color: $darkBgBoxContent;
  }

  &.light {
    background-color: $light;
  }
}