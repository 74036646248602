@import "variables";

.box-label {
  background-color: #c4d6df66;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  .title-box {
    top: -10px;
    position: absolute;
    color: white;
    border-radius: 8px;
    padding: 0px 6px;
    background-color: #f58e53;
  }
}
