@import "variables";

.shared-msg {
  margin-bottom: 4px;

  .toast {
    border-radius: 8px;

    & .toast-header {
      min-height: 40px;
      font-size: 16px;
      background-color: transparent;
      padding: 0px;
      border-radius: $border-radius+12px;
      border: none;
      display: flex;

      & .close {
        span {
          color: #000;
        }

        &.ml-2 {
          margin-left: 16px !important;
        }
      }

      .button {
        order: 1
      }

      .shared-icon {
        order: 2
      }

      strong {
        order: 3
      }
    }

    & .toast-body {
      padding: 0px 24px;
      padding-bottom: 24px;
      padding-left: 36px;
      margin-top: -28px;

      .content-body {

        max-height: 180px;
        overflow: auto;
      }
    }
  }

  transition: all 0.4s ease-in-out;

  &.in {
    transform: translateX(0px);
  }

  &.out {
    transform: translateX(-400px);
  }
}