@import "variables";

.shared-textArea {
  width: $widthTextArea;
  height: $heightTextArea;
  border-radius: $borderRadiusTextArea;
  font-size: $fontSizeTextArea;
  font-family: $fontFamilyTextArea;
  background: $bgInput;
  border-radius: $borderRadiusInput;
  border: none;

  &.error {
    border-color: $error;
  }
}