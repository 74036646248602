.menu-container {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0px;
    background-color: #fff;
    width: 100%;
    z-index: 10;

    & .menu-item {
        border: 1px solid $accent-primary;
        width: 25%;
        padding: 14px;
        border-right: 0px;

        &:first-child {
            border-left: 0px;
        }

        .label-menu {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 24px;
                height: 24px;
            }

            span {
                margin-top: 4px;
                text-transform: uppercase;
                color: rgb(99, 99, 99);
                font-size: 11px;
            }
        }

        &.active {
            border-top: 0px;
            background-color: rgb(227, 227, 227);
        }
    }
}
