.list-user-chat {
    .card-detail {
        width: 80%;
    }

    .form-list-user {
        max-height: calc(#{vhVar(100)} - 320px);

        .content {
            padding: 20px 0px;
        }
    }

    .shared-checkbox {
        padding: 8px 8px;

        .check:before {
            top: 5px;
        }

        .check:after {
            top: 5px !important;
            left: 8px !important;
        }

        &:nth-child(odd) {
            background-color: rgba($accent-primary, 0.2);
            border-top: 1px solid rgba($accent-primary, 0.2);
            border-bottom: 1px solid rgba($accent-primary, 0.2);
        }
    }
}

@media (max-width: $md-device) {
    .list-user-chat {
        .card-detail {
            width: 90%;
        }

        .form-list-user {
            max-height: calc(vh(100) - 375px);
        }
    }
}