@import "variables";

.shared-select-react {
  & .css-yk16xz-control {
    background: $bgSelect;
    border-radius: $borderRadiusInputSelect;
    border: none;

    &.css-g1d714-ValueContainer {
      padding: 2px 16px;
    }

    & .css-1wa3eu0-placeholder {
      font-family: 'Helvetica';
    }

    & .css-b8ldur-Input {}
  }

  input {
    font-family: 'Helvetica Neue', 'helvetica';
  }
  
  & .css-1pahdxg-control {
    font-family: 'Helvetica Neue', 'helvetica';
    background: $bgSelect;
    border-radius: $borderRadiusInputSelect;
    border: none;
    box-shadow: none;
  }

  & .css-26l3qy-menu, .css-1w9j89e-menu {
    border-radius: 0px;
    & .css-4ljt47-MenuList {
      font-family: 'Helvetica';
      border: 1px solid rgb(228, 228, 228);
      &>div {
        background-color: white;
        color: #666;

        &:hover {
          background-color: $bgSelect;
        }

        &:active {
          background-color: #ccc;
        }
      }
    }
  }
}





.shared-select {
  &.error {
    border-color: $errorInputSelect;
  }

  color: #565b6f;
  font-size: $fontSizeInputSelect;
  font-family: $fontFamilyInputSelect;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: $heightInputSelect;
  padding-right: 30px;
  border: none;
  background: $bgSelect url('./assets/arrow-down.svg') no-repeat right 0.8rem center/22px 20px;
  border-radius: $borderRadiusInputSelect;

  &.customSelect {
    background: transparent;
    padding-right: 0px;
    height: $heightMediumInputSelect;
    border: 1px solid #fff;
    border-radius: 0px;
    color: #fff;
    width: calc(100% - #{$heightMediumInputSelect});
    cursor: pointer;

    option {
      color: #565b6f;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:disabled~.custom.iconAction {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }

    &~.custom.iconAction {
      min-width: $heightMediumInputSelect;
      height: $heightMediumInputSelect;
      background-color: #eef8ff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      cursor: pointer;
    }

    &:focus {
      box-shadow: none;
      background: transparent;
    }
  }
}

.multi-select {
  --rmsc-bg: transparent !important;
  --rmsc-h: 38px !important;
  --rmsc-gray: #565b6f !important;

  &:disabled,
  &.disabled {
    //opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    background-color: #e9ecef;
  }

  &.error {
    & .dropdown-container {
      border-color: $errorInputSelect;
    }
  }

  .dropdown-content {
    & .panel-content {
      margin-top: 2px;
      background-color: #fff;
    }
  }

  .dropdown-heading-dropdown-arrow {
    visibility: hidden;
  }

  .dropdown-container {

    &:focus,
    &:focus-within {
      color: #495057;
      background-color: #fff;
      border-color: $borderColorInputSelect;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(19, 42, 136, 0.25);
    }

    .dropdown-heading {
      &:focus {
        color: #495057;
        background-color: #fff;
        border-color: $borderColorInputSelect;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(19, 42, 136, 0.25);
      }
    }
  }
}

.md {
  height: $heightMediumInputSelect;
  font-size: $fontSizeMediumInputSelect;
  line-height: $lineheightMediumInputSelect;
}

.sm {
  height: $heightSmallInputSelect;
  font-size: $fontSizeSmallInputSelect;
  line-height: $lineheightSmallInputSelect;
}