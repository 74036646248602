@import "variables";
.shared-toastSuccess {
  .toast {
    border: none;
    & .toast-header {
     // border-radius: 8px;
      & .close {
        color: #fff;
      }
    }
  }
}
