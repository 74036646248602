@import "variables";
.shared-inputSwitch {
  &.error {
    & .custom-control-label {
      &::before {
        background-color: $colorSwitchError;
      }
    }
  }
  & .custom-control-label {
    cursor: pointer;
    &::after {
      background-color: $colorSwitchOff;
      width: $widthCircle;
      height: $widthCircle;
    }
    &::before {
      background-color: $colorBgSwitchOff;
      border: none;
      width: $widthSwitch;
      height: $heightSwitch;
      border-radius: $radiusSwitch;
    }
  }
  .custom-control-input {
    &:checked ~ .custom-control-label {
      &::before {
        background-color: $colorBgSwitchOn;
        width: $widthSwitch;
        height: $heightSwitch;
        border-radius: $radiusSwitch;
      }
      &::after {
        background-color: $colorSwitchOn;
        width: $widthCircle;
        height: $widthCircle;
        left: $positionOn;
      }
    }
    &:not(:disabled):active ~ .custom-control-label::before {
      color: #fff;
      background-color: $colorBgSwitchOff;
      border: none;
    }
  }
}
