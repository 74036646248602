@import "variables";
@import "../../../shared/mediaQuery";

.heading-1 {
  font-family: $fontFamilyHeading1;
  font-size: $fontSizeHeading1;
  color: $colorHeading1;
  letter-spacing: $letterSpacingHeading1;
  line-height: $lineHeightHeading1;
  @media (max-width: $lg-device) {
    font-size: $fontSizeHeading1Mobile;
  }
}
