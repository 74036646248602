@import "../../../components/shared/mediaQuery";

.navbar-app {

    &.navbar {
        padding: 0px;
        align-items: flex-start;
    }

    & .flap {
        padding: 8px;

        &.left {
            border-bottom-right-radius: 30px;
            padding-right: 20px;
        }

        &.right {
            border-bottom-left-radius: 30px;
            padding-left: 20px;
        }

        &.dark {
            color: $light;
            background-color: $dark;
        }

        &.light {
            background-color: $light;
            color: $dark;
        }
    }

    & .collapse:not(.show) {
        display: block;
    }

    & .dropdown-toggle {
        &::after {
            display: none;
        }
    }


    .dropdown-menu {
        top: 60px;
        border: none;
    }
}

@media (max-width: $md-device) {
    .navbar-app {
        background-color: #fff;

        &.navbar {
            align-items: center;
            display: flex;
            padding: 16px;
        }

        .flap {
            padding: 0px;

            &.left {
                border-bottom-right-radius: 0px;
                padding-right: 0px;

                img {
                    height: 32px
                }
            }

            &.right {
                border-bottom-left-radius: 0px;
                padding-left: 0px;
                margin-right: -16px;

                img {
                    width: 28px;
                    height: 28px
                }
            }
        }

        & .dropdown {
            padding: 0px 18px;
            display: flex;
            align-items: center;
            height: 56px;

            &.show {
                background-color: rgb(227, 227, 227);
            }
        }

        & .dropdown-menu {
            position: fixed !important;
            height: 100%;
            background-color: rgb(227, 227, 227);
            top: 72px;
            border-radius: 0px;
            border: none;
            z-index: 1000;
            overflow: auto;
            -webkit-overflow-scrolling: touch;

            &.dropdown-menu-right {
                left: 0px;
                padding: 38px;


                & .dropdown-item {
                    font-size: 20px;
                    border-bottom: 1px solid rgb(177, 177, 177);
                    padding: 10px;

                    span {
                        color: #545454;
                    }
                }
            }
        }

        &.fixed-top {
            z-index: 1050;
        }
    }
}