.area-content {
    height: calc(#{vhVar(100)} - 162px);
    display: block;
    align-items: center;
    padding: 0px 20px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 25px;
    //padding-bottom: 10px;

    .item-area {
        padding: 12px;
        width: 100%;
        border-radius: 20px;
        background-color: #fff;
        margin-bottom: 8px;
        text-align: center;
        text-transform: uppercase;
        color: rgb(99, 99, 99);
        font-weight: 500;

        &.active {
            background-color: red;
            color: #fff;
        }
    }
}