@import "variables";

.shared-tabs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;

  & .tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    //background: #f5f6f9;
    height: 38px;
    //border-radius: 21px;
    min-width: 180px;
    font-family: 'Helvetica Neue', 'helvetica';
    text-transform: uppercase;
    font-size: 16px;
    //margin-right: 12px;
    margin-bottom: 6px;
    cursor: pointer;
    border-bottom: 1px solid #000;
    flex-basis: 50%;

    &.active {
      // background: #1b3aa6;
      // color: #fff;
      border-bottom: 4px solid #000;
    }

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}