@import "variables";

.shared-button.sh-secondary {
  border: $borderSecondaryButton;
  color: $colorSecondaryButton;
  //box-shadow: $btn-box-shadow;
  width: $widthButton;

  &:disabled {
    color: #666;
    border-color: #666;
    opacity: 0.2;
  }
}