@import "variables";

.progress-bar-container {
  height: $progressBarHeight;
  width: $progressBarWidth;
  border-radius: $progressBarBorderRadius;
  //margin: $progressBarMargin;
}

.progress-bar-filler {
  height: 100%;
  border-radius: inherit;
  text-align: right;
  transition: width linear;
}

.progress-bar-label {
  padding: $progressBarLabelPadding;
  color: #fff;
  font-weight: bold;
}

.progress-bar-vertical {
  width: $progressBarHeight;
  border-radius: $progressBarBorderRadius;
  height: 100%;
  display: flex;
  align-items: flex-end;
  margin-left: 24px;
  margin-right: 14px;
}

.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 1s ease;
  -o-transition: height 1s ease;
  transition: height 1s ease;
}
