$width-call-container: 1100px;
$height-call-container: vh(78);


.call-container {
    position: fixed;
    z-index: 1;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    height: $height-call-container;
    width: 100%;
    max-width: $width-call-container;
    margin: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 30px;
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.2);
    background-color: #fff;

    & #local_video_element_id {
        width: 150px;
        position: absolute;
        right: 0px;
        background: white;
        padding: 12px;
        border-radius: 30px;
        margin: 12px;
    }

    & #remote_video_element_id {
        width: 100%;
    }

    & .btn-toolbar {
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100%;
        bottom: 20px;

        .btn-item {
            display: flex;
            align-items: center;
            width: auto;
        }
    }
}

.ring-call {
    position: absolute;
    z-index: 1;
    right: 30px;
    top: 90px;
}
