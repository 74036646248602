@import "channel";
@import "rooms";
@import "personal-rooms";
@import "list-user";
@import "profile";
@import "call-view";

$width-sendBird: 1100px;
$height-sendBird: calc(#{vhVar(100)} - 160px);
$border-radius:8px;
$height-header-chat:80px;

.leftAlign {
    z-index: 1;
    position: fixed;
    bottom: 0;
    left: 5px;
    max-height: vh(80);
    width: 350px;

    .header-mini-chat {
        background-color: #f5f5f5;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        display: flex;
        padding: 6px;
        justify-content: flex-end;
    }
}

.center-div-custom {
    z-index: 1;
    position: fixed;
    top: 65px;
    left: 50%;
    transform: translateX(-50%);
    height: $height-sendBird;
    width: 100%;
    max-width: $width-sendBird;
    margin: auto;
    overflow: hidden;

    @media (max-width: $md-device) {
        &>.card-detail {
            width: 100%;
            height: calc(#{vhVar(100)} - 160px);
        }
    }
}

.sendBird-app {
    --sendbird-dark-background-600: #000;
    @extend .center-div-custom;
    border-radius: 30px;
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.2);

    .sendbird-app__wrap {
        display: flex;
        flex-direction: column;
        background: #fff;

        .header-chat {
            background-color: rgba(#ccc, 0.3);
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: $height-header-chat;

            h4 {
                flex-basis: 320px;
                margin-bottom: 0px;
                padding: 20px;
            }

            .img {
                margin: auto;
                width: 60px;
                height: 60px;
                border-radius: 30px;
                cursor: pointer;

                img {
                    width: 60px;
                    height: 60px;
                    border-radius: 30px;
                }
            }

            .title-chat {
                font-style: italic;
                font-weight: 100;
                font-size: 14px;

                sup {
                    font-size: 16px;
                }

            }

            .icon {
                color: $accent-primary;
                font-size: 28px;
                font-weight: 600;
                background: white;
                border-radius: 50%;

                &.bordered {
                    border: 2px solid $accent-primary;
                    transform: scale(0.9);
                }
            }
        }

        .message-chat {
            display: flex;
            align-items: stretch;
            height: calc(100% - #{$height-header-chat});
        }

        .right-panel {
            border-left: 1px solid var(--sendbird-light-onlight-04);
            padding: 12px 24px;

            .panel {
                h5 {
                    margin-bottom: 24px;
                }

                .item-member {
                    cursor: pointer;

                    span {
                        margin-left: 12px;
                        border-bottom: 1px solid var(--sendbird-light-onlight-04);
                        padding-bottom: 6px;
                    }

                    img {
                        width: 32px;
                        height: 32px;
                        border-radius: 16px;
                    }

                }
            }
        }

        .sendbird-app__channellist-wrap {
            width: 320px;
            background: #fff;
            border-right: 1px solid var(--sendbird-light-onlight-04);
            border-bottom: none !important;

            & .sendbird-channel-list {
                border-bottom-left-radius: $border-radius;
                border-top-left-radius: $border-radius;

                & .sendbird-channel-list__header {
                    border-top-left-radius: $border-radius;

                    & .sendbird-channel-header {
                        border-top-left-radius: $border-radius;
                    }
                }
            }

            .chat-container {
                display: flex;
                flex-direction: column;
                height: calc(100% - #{$height-header-chat});
            }
        }


        .sendbird-app__conversation-wrap {
            & .sendbird-conversation {
                border: none !important;
                border-bottom-right-radius: $border-radius;
                border-top-right-radius: $border-radius;

                .sendbird-conversation__padding {
                    flex: none
                }

                .sendbird-conversation__footer {
                    .sendbird-message-input--textarea {
                        border: none;
                        border-bottom: 3px solid #1f1f1f;
                        border-radius: 0px;
                        background-color: rgba(#ccc, 0.3);
                    }
                }
            }
        }
    }
}

@import "chat-mobile";