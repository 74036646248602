@import "variables";
@import "variables";

.shared-input {
  width: $widthInput;
  height: $heightInput;
  border-radius: $borderRadiusInput;
  font-size: $fontSizeInput;
  font-family: $fontFamilyInput;
  border: none;
  background: $bgInput;
  padding-right: 10px;

  display: flex;
  align-items: center;

  //box-shadow: $boxShadow;
  &.error {
    border: 1px solid $errorInput;
  }


  &.file {
    display: none;
  }

  & .btn-upload {
    width: 120px;
    margin: 0px 8px;

    & label {
      cursor: pointer;
    }
  }
}