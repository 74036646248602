.live-content {
    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 14px;
    }

    .text {
        font-size: 19px;
        font-weight: 200;
        line-height: normal;
    }
}

.qna-box {
    min-width: 330px;
    height: 300px;
    background-color: #fff;
    position: absolute;
    right: 15px;
    bottom: 15px;
    z-index: 1000000;
    border-radius: 30px;
    -webkit-box-shadow: 9px 11px 25px -6px rgba(0, 0, 0, 0.44);
    box-shadow: 9px 11px 25px -6px rgba(0, 0, 0, 0.44);

    .header {
        padding: 15px;
        display: flex;
        justify-content: space-between;
    }

    .body {
        padding: 15px;

    }
}

.qna-icon {
    font-size: 17px;
    border: 1px solid $accent-primary;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
    background-color: rgb(233, 233, 233);
}