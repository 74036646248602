@import "variables";
.shared-radio {
  display: flex;
  align-items: center;
  .radio {
    visibility: hidden;
    width: $sizeEmpty;
    height: $sizeEmpty;
    &:before {
      visibility: visible;
      font-family: $fontFamilyIconRadio;
      font-size: $sizeEmpty;
      content: $iconEmptyRadio;
      position: absolute;
      color: $colorRadio;
    }
    &:checked {
      &:after {
        visibility: visible;
        font-family: $fontFamilyIconRadio;
        content: $iconCheckRadio;
        position: absolute;
        font-size: $sizeRadio;
        color: $colorRadio;
        top: 0px;
        left: 0px;
      }
    }
  }
  .input-group-text {
    background-color: transparent;
    border: none;
    padding: 0px;
    line-height: 1;
  }
}
