@import "variables";

.shared-infoNumber {
  background: #1b3aa6;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 50%;
  color: white;
  position: absolute;
  top: -8px;
  left: 16px;
  font-size: 12px;
  font-weight: bold;
  z-index: 10;
}
