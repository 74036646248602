.menu-app {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    &.fixed-bottom {
        right: auto;
        left: auto;
    }

    &.bg-dark {
        color: $light;
    }

    &.bg-light {
        color: $dark;
    }

    @media (max-width: $lg-device) {
        padding: 0px 4px !important;

    }

    @media (max-width: $md-device) {
        padding: 0px 4px !important;
    }

    .menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4px 18px;
        text-transform: uppercase;
        cursor: pointer;

        @media (max-width: $lg-device) {
            padding: 2px 4px;
            transform: scale(0.8);
        }

        @media (max-width: $md-device) {
            padding: 0px;
            transform: scale(0.6);
        }
    }
}