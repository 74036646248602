.shared-messageList {
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.shared-errorMessage,
.shared-successMessage {
    color: $success;
    padding: 16px;
    background-color: white;
    min-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid $success;
    margin-bottom: 12px;

    img {
        &:hover {
            opacity: 0.5;
            cursor: pointer;
        }
    }
}

.shared-errorMessage {
    color: $error;
    border: 1px solid $error;
}