@import "variables";

.card-detail {
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 50%;
  left: 50%;
  // z-index: 2;
  z-index: 1;
  transform: translate(-50%, -50%);

  .header-detail {
    background-color: #f2f2f2;
    min-height: 30px;
    width: 100%;
    padding: 12px 0px 12px 24px;
    display: flex;
    justify-content: space-between;

    h5 {
      line-height: normal;
      margin-bottom: 0px;
      font-weight: bold;
      color: #4D4D4D;
      font-size: 22px;
    }

    .icon {
      margin-right: 12px;
      margin-left: 12px;
    }
  }

  .body-detail {
    padding: 24px;
    padding-bottom: 42px;
    height: calc(100% - #{$height-header});
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media (max-width: $md-device) {
    border-radius: 0px;

    .header-detail {
      background-color: $accent-primary;

      h5 {
        color: #fff;
        font-weight: 500;
      }

      .icon {
        &::before {
          color: #fff;
        }
      }
    }

    .body-detail {
      padding: 18px;
    }
  }
}