@mixin box-shadow-bottom {
  box-shadow: 0px 6px 12px -3px rgba(0, 0, 0, 0.2);
}
@mixin box-shadow-left {
  box-shadow: -6px 0px 12px -3px rgba(0, 0, 0, 0.2);
}
@mixin box-shadow-center {
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.1);
}
@mixin box-shadow-center-hover {
  &:hover {
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.1), 0 7px 7px rgba(0, 0, 0, 0.1);
  }
}
@mixin box-shadow-top {
  box-shadow: 0px -2px 6px -3px rgba(0, 0, 0, 0.2);
}
