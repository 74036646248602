@import "variables";
.shared-toastSuccess {
  .toast {
    border: 1px solid #00a100;
    & .toast-header {
     // border-radius: 8px;
      & .close {
        color: #565b6f;
      }
    }
  }
}
