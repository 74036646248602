$errorInputSelect: #a10000 !default;

$bgSelect:#e6e6e6 !default;
$widthInputSelect: 100% !default;

$heightInputSelect: 38px !default;
$heightMediumInputSelect: 28px !default;
$heightSmallInputSelect: 24px !default;

$fontFamilyInputSelect: 'Helvetica Neue', 'helvetica' !default;

$fontSizeInputSelect: 14px !default;
$fontSizeMediumInputSelect: 16px !default;
$fontSizeSmallInputSelect: 16px !default;

$lineheightMediumInputSelect: 24px !default;
$lineheightSmallInputSelect: 19px !default;

$borderRadiusInputSelect: $heightInputSelect/2 !default;
$borderColorInputSelect: #3759e3 !default;
$boxShadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
