 .rooms {
     background-color: #fff;
     display: flex;
     flex-direction: column;
     padding: 12px;
     padding-left: 30px;
     border-top: 1px solid #f0f0f0;
     position: relative;
     flex-basis: auto;
     overflow: hidden;
     
     .content-item {
         overflow: auto;
         -webkit-overflow-scrolling: touch;
         flex-basis: auto;
         transition: all 0.8s cubic-bezier(0.25,0.1,0.25,1);
     }

     .shared-icon {
         position: absolute;
         bottom: -4px;
         right: 2px;
     }

     .list-rooms {

         & .cover {
             width: 36px;
             height: 36px;
             border-radius: 50%;
         }

         & .text-ellipse {
             white-space: nowrap;
             overflow: hidden;
             text-overflow: ellipsis;
         }

         span {
             line-height: normal;
         }

         & .notify {
             width: 24px;
             height: 24px;
             padding: 2px;
             border-radius: 50%;
             background-color: $accent-primary;
             color: #fff;
             position: absolute;
             display: flex;
             justify-content: center;
             align-items: center;
             font-size: 14px;
             top: -6px;
             left: -6px;
         }

         & .state {
             font-size: 12px;
             color: #666;
         }

         & .item-room {
             margin-left: 20px;
             padding: 8px;
             cursor: pointer;
             display: flex;
             align-items: center;

             &.active {
                 background-color: rgba(#ccc, 0.3);
             }

             span {
                 margin-left: 12px;
             }

             small {
                 font-size: 10px;
             }
         }
     }

     ::-webkit-scrollbar-track {
         -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
         background-color: #f5f5f5;
         border-radius: 3px;
     }

     ::-webkit-scrollbar {
         width: 3px;
         height: 3px;
         background-color: #f5f5f5;
     }

     ::-webkit-scrollbar-thumb {
         border-radius: 10px;
         background-color: #f5f5f5;
         background-image: -webkit-gradient(linear,
                 40% 0%,
                 75% 84%,
                 from(#353535cc),
                 to(#353535cc),
                 color-stop(0.6, #353535cc));
     }

     :root {
         scrollbar-color: #000 #f5f5f5;
         scrollbar-width: thin;
     }
 }