@import "login";
@import "PublicHome";
@import "authHome";


.users-row {
  display: flex;
  align-items: center;
  padding: 8px;
  width: 60vw;

  .thumb-user {
    width: 46px;
    height: 46px;
  }

  &:nth-child(odd) {
    background-color: rgba($accent-primary, 0.2);
    border-top: 1px solid rgba($accent-primary, 0.2);
    border-bottom: 1px solid rgba($accent-primary, 0.2);
  }

  .item-name {
    display: flex;
    align-items: center;
  }

  .btn-action {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

.profile-row {
  display: flex;
  align-items: center;
  padding: 8px;
  border-top: 1px solid rgba($accent-primary, 0.2);
  border-bottom: 1px solid rgba($accent-primary, 0.2);

  .thumb-user {
    width: 46px;
    height: 46px;
  }

  &:nth-child(odd) {
    border-bottom: none;
  }

  .item-name {
    display: flex;
    align-items: center;
  }

  .btn-action {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

.searchRow {
  display: flex;
  margin: 0px -24px;
  background-color: rgba(#999999, 0.5);
  padding: 8px;
  padding-left: 30px;
  //width: 60vw;

  .form-group {
    margin-bottom: 0px;

    .shared-error {
      margin: 0px;
      padding: 0px;
    }

    .shared-label {
      color: #000;
      font-weight: 500;
    }
  }


}



.input-group.sharedInputGroup {
  &.inputBordered {

    & input,
    & textarea {

      border: 1px solid $color-accent;
      border-radius: 4px;
      height: 30px;
      background-color: #fff;
    }

    &>.input-group-append {
      border-radius: 4px;
      border: 1px solid $color-accent;
      border-left: none;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      background-color: #fff;

      &>.shared-icon {
        color: $accent-primary;
      }
    }

  }
}

.shared-select-react {
  &.inputBordered {
    & .css-yk16xz-control {
      background: #fff;
      border-radius: 4px;
      border: 1px solid $color-accent;
      height: 30px;
      min-height: 30px;

      & .css-g1d714-ValueContainer {
        padding: 4px;
        position: initial;
        font-size: 14px;
      }

      & .css-1hb7zxy-IndicatorsContainer {
        height: 100%;
      }
    }

    & .css-1pahdxg-control {
      background: #fff;
      border-radius: 4px;
      border: 1px solid $color-accent;
      height: 30px;
      min-height: 30px;

      & .css-g1d714-ValueContainer {
        padding: 4px;
        position: initial;
        font-size: 14px;
      }

      & .css-1hb7zxy-IndicatorsContainer {
        height: 100%;
      }
    }
  }
}

@media (max-width: $md-device) {
  .searchRow {
    .form-group {
      margin-bottom: 6px;
    }

    .shared-button {
      margin-bottom: 6px;

    }
  }
}