@import "variables";
.shared-contentBoxEmpty {
  min-height: 124px;
  & span {
    font-family: 'Helvetica Neue', 'helvetica';
  }
  &.bg::before {
    content: "\e901";
    font-family: "icon-pack";
    position: absolute;
    font-size: 124px;
    opacity: 0.1;
  }
}
