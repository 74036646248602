$accent-primary: #81358A;

.header-tab-calendar {
    margin-bottom: -12px;

    button {
        border: 1px solid #fff;
        border-bottom: none;
        background: #f2f2f2;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 6px 16px 6px 16px;

        &.active {
            background: #fff;
        }
    }

    @media (max-width: $lg-device) {
        display: flex;
        font-size: 0.7em;
    }
}

.fc {
    @media (max-width: $lg-device) {
        font-size: 0.7em !important;
    }

    &.fc-media-screen {
        --fc-event-border-color: #81358A;
        --fc-event-bg-color: #81358A;
        --fc-today-bg-color: #81358a15;

        --fc-button-bg-color: #81358acc;
        --fc-button-border-color: #81358Acc;

        --fc-button-active-bg-color: #81358A;
        --fc-button-active-border-color: #81358A;
        --fc-button-hover-bg-color: #81358A;

        .fc-header-toolbar {
            .fc-toolbar-chunk {
                .fc-toolbar-title {
                    font-size: 16px;
                }

                .fc-button-group {
                    .fc-button {
                        border-radius: 16px;
                        padding-top: 4px;
                        padding-bottom: 4px;

                        &:not(:last-child) {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        &:not(:first-child) {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }

                }

                & .fc-button.fc-button-primary {
                    border-radius: 16px;
                    padding-top: 4px;
                    padding-bottom: 4px;
                }
            }
        }

        .meeting {
            background-color: $success;
        }

    }

}

@media (max-width: $md-device) {
    .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 8px !important;
    }

}