@import "variables";

.pagination {
  margin-top: 18px;
  .page-item {
    &.active {
      .page-link {
        background-color: #7ed321;
        color: #fff;
        //margin-top: -12px;
        &:hover {
          background: #7dd321c0;
          opacity: 1;
        }
      }
    }
    & .page-link {
      border: none;
      padding: 7px 12px;
      font-size: 12px;
      background-color: #f5f6f9;
      color: #445167;
      margin: 6px;
      border-radius: 4px;
      font-family: 'Helvetica Neue', 'helvetica';
      z-index: 0;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        background: none;
        opacity: 0.5;
      }
    }
    &.prevPage {
      & .page-link {
        & span:not(.sr-only) {
          visibility: hidden;
        }
        & span:not(.sr-only)::after {
          font-family: $fontFamilyArrowIconPagination;
          content: $arrowLeftPagination;
          visibility: visible;
          font-size: $fontSizeArrowPagination;
          color: #abadb7;
          top: 0px;
          position: relative;
        }
      }
    }
    &.nextPage {
      & .page-link {
        & span:not(.sr-only) {
          visibility: hidden;
        }
        & span:not(.sr-only)::after {
          font-family: $fontFamilyArrowIconPagination;
          content: $arrowRightPagination;
          visibility: visible;
          font-size: $fontSizeArrowPagination;
          color: #abadb7;
          top: 0px;
          position: relative;
        }
      }
    }
    &.firstPage {
      & .page-link {
        //padding-right: 0px;
        & span:not(.sr-only) {
          visibility: hidden;
        }
        & span:not(.sr-only)::after {
          font-family: $fontFamilyArrowIconPagination;
          content: $arrowFirstPagination;
          visibility: visible;
          font-size: $fontSizeArrowPagination;
          color: #abadb7;
          top: 0px;
          position: relative;
        }
      }
    }
    &.lastPage {
      & .page-link {
        //padding-left: 0px;
        & span:not(.sr-only) {
          visibility: hidden;
        }
        & span:not(.sr-only)::after {
          font-family: $fontFamilyArrowIconPagination;
          content: $arrowLastPagination;
          visibility: visible;
          font-size: $fontSizeArrowPagination;
          color: #abadb7;
          top: 0px;
          position: relative;
        }
      }
    }
  }
}
