@import "variables";
@import "./components/pagination";

table.table-custom {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 2px;
  thead {
    background: $bgTHeader;
    color: $colorTHeader;
    tr {
      font-family: 'Helvetica Neue', 'helvetica';
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      th {
        padding: 12px 6px;
        border: none;
        vertical-align: top;
        &:first-child {
          border-top-left-radius: $radius;
          border-bottom-left-radius: $radius;
        }
        &:last-child {
          border-top-right-radius: $radius;
          border-bottom-right-radius: $radius;
        }
        & div {
          width: max-content;
        }
      }
    }
  }
  tbody {
    tr {
      border: 1px solid $borderColorTRow;
      font-size: 14px;
      color: $colorTRow;
      &.row-pointer {
        cursor: pointer;
        &:hover {
          background-color: #f5f6f966;
        }
      }
      &.row-disabled {
        cursor: not-allowed;
        opacity: 0.2;
      }
      td {
        padding: 4px 6px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom: 1px solid $borderColorTRow;
        vertical-align: middle;
        &:first-child {
          border-left: 1px solid $borderColorTRow;
          border-top-left-radius: $radius;
          border-bottom-left-radius: $radius;
        }
        &:last-child {
          border-right: 1px solid $borderColorTRow;
          border-top-right-radius: $radius;
          border-bottom-right-radius: $radius;
        }
        &.contentRow {
          position: relative;
          top: -7px;
          background-color: #f5f6f9;
          & .shared-tableDataEmpty {
            display: flex;
            justify-content: center;
            background-color: transparent;
            & td {
              border: none;
              padding: 8px;
            }
          }
        }
      }
      &.rowActive {
        & > td {
          border-top: 1px solid $colorTRowActive;
          border-bottom: 1px solid $colorTRowActive;
          &:first-child {
            border-left: 1px solid $colorTRowActive;
          }
          &:last-child {
            border-right: 1px solid $colorTRowActive;
          }
        }
      }
    }
  }
  &.solid {
    border-spacing: 0 2px;
    width: 90%;
    margin: 0px auto;
    & thead {
      background: $bgTHeader;
      tr {
        font-family: 'Helvetica Neue', 'helvetica';
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #565b6f;
        th {
          padding: 0px 20px 0px 6px;
          border: none;
          &:first-child {
            border-top-left-radius: $radius;
            border-bottom-left-radius: $radius;
          }
          &:last-child {
            border-top-right-radius: $radius;
            border-bottom-right-radius: $radius;
          }
        }
      }
    }
    & tbody {
      tr {
        background-color: #ffffff;
        border: none;
        font-size: 14px;
        &:nth-child(even) {
          background-color: #fcfcfc;
        }
      }
      td {
        padding: 2px 20px 2px 6px;
        border: none;
        font-size: 12px;
        &:first-child {
          border: none;
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
}
